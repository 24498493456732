@import "../../styles/variables.module";

.loading_wrapper {
  position: fixed;
  background: white;
  inset: 0;
  z-index: 9999;
  background: white;
  pointer-events: none;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  img {
    &:first-child {
      width: 15rem;
      margin-bottom: 20rem;
    }

    &:last-child {
      animation-name: spin;
      animation-duration: 3000ms;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
    }
  }
}

@media (min-width: ($bp-s)) {
  .loading_wrapper {
    img {
      &:first-child {
        width: 38rem;
        margin-bottom: 20rem;
      }
    }
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
