@import "../variables.module";

.notifications {
  background: $input;
  border-top-left-radius: 1.6rem;
  border-top-right-radius: 1.6rem;
  margin: 0 -1.6rem;
  position: relative;

  & .relative {
    position: relative;
  }

  .heading {
    display: flex;
    align-items: center;
    margin: 0;
    justify-content: space-between;
    padding: 20px 20px 20px 0;

    .cross {
      float: right;
      cursor: pointer;
    }

    .title {
      padding: 0 2rem;
    }
  }

  .items {
    display: flex;
    width: 100%;
    flex-direction: column;
    overflow: hidden;

    .subtitle {
      text-align: center;
      color: $primary-dark-green;
      font-size: 16px;
      line-height: 1.5;
    }

    .no_notifications {
      margin: auto;
      margin-top: 120px;
    }

    & .item_wrapper {
      position: relative;
      &.unread {
        background-color: #dcf3f4;
        position: relative;

        &:after {
          content: "";
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
          background: #dcf3f4;
          width: 100vw;
          left: 50%;
          z-index: -1;
        }
      }
    }

    & .item {
      width: 100%;
      border-bottom: 2px solid $blue-bg;
      padding: 2rem;
      display: flex;
      align-items: center;
      max-width: 800px;
      margin-right: auto;

      & .icon {
        border-radius: 100%;
        width: 5.4rem;
        height: 5.4rem;
        display: flex;
        justify-content: center;
        align-items: center;
        flex: none;
      }

      & .info {
        display: flex;
        flex-direction: column;
        margin-left: 1.6rem;
        flex: 1;
        padding-right: 1.6rem;

        & .title {
          font-weight: 700;
          font-size: 1.6rem;
          line-height: 150%;
          color: $text;
        }

        & .description {
          font-weight: 400;
          font-size: 1.6rem;
          line-height: 150%;
          color: $text;
        }
      }

      & .date {
        margin-left: auto;
        font-style: normal;
        margin-right: 10px;
        font-weight: 400;
        font-size: 1.4rem;
        line-height: 150%;
        color: $grey;
        align-self: flex-start;
      }

      & .dots {
        display: none;
        cursor: pointer;
        background-color: $blue-card;
        border-radius: 100%;
        width: 36px;
        height: 36px;
        justify-content: center;
        align-items: center;
        filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.25));
      }

      & .actions {
        display: flex;
        flex-direction: column;
        position: absolute;
        right: 110px;
        top: 85px;
        background-color: $white;
        padding: 12px;
        border-radius: 5px;
        z-index: 100;

        &:before {
          content: "";
          position: absolute;
          width: 15px;
          height: 15px;
          bottom: 100%;
          right: 10px;
          background: white;
          background: linear-gradient(
            to bottom right,
            transparent 50%,
            white 50%
          );
        }

        > div {
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 150%;
          color: $primary-dark-green;
          cursor: pointer;
          padding: 3px 0 3px 30px;

          &.deleted {
            background: url("/img/notification_delete.svg") no-repeat left
              center;
          }
          &.unread {
            background: url("/img/notification_unread.svg") no-repeat left
              center;
          }
          &.read {
            background: url("/img/notification_read.svg") no-repeat left center;
          }
        }
      }
    }

    & .swipedActions {
      position: absolute;
      top: 0;
      right: 0;
      width: 180px;
      display: flex;
      height: calc(100% - 2px);
      cursor: pointer;
      font-size: 1rem;
      line-height: 1.5rem;
      font-weight: bold;
      color: white;

      > div {
        display: flex;
        flex-direction: column;

        > img {
          margin-bottom: 5px;
        }
      }

      & .delete {
        background-color: $secondary-dark-pink;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 90px;
      }
      & .unread,
      & .read {
        background-color: $light-gray;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 90px;
      }
    }
  }
}

@media screen and (max-width: $bp-xs) {
  .notifications {
    flex: 1 1;
  }

  .no_notifications {
    margin-top: 0 !important;
    width: 20rem;
  }
}

@media screen and (min-width: ($bp-s+1px)) {
  .notifications {
    background: $input;
    border-top-right-radius: 0;
    padding: 0;
    flex: 1 1;
    height: 100%;
    margin: 7rem -1.6rem 0 0;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      background: $input;
      width: 100vw;
      left: 50%;
      z-index: -1;
    }

    &:after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      top: 100%;
      bottom: 0;
      background: $input;
      height: 40px;
      width: 100vw;
    }

    .heading {
      padding: 0 2rem;
      .cross {
        margin-left: auto;
        padding: 38px 0 38px 72px;
      }
      .title {
        padding: 0;
      }
    }

    .item {
      &:hover .dots {
        display: flex;
        position: absolute;
        right: 100px;
      }
    }
  }

  .items {
    overflow: unset !important;
  }
}

@media screen and (min-width: ($bp-s+1px)) {
  .notifications {
    .heading {
      margin-left: 72px;
      max-width: 790px;
    }

    .item {
      margin-left: 72px;
    }
  }
}
