@import "../../styles/variables.module";

.modal {
  font-size: 1.2rem;
  padding: 8rem;
  max-height: 90vh;
  overflow-y: auto;
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
}

.modal > .title {
  width: 100%;
  font-weight: 700;
  font-size: 1.6rem;
  line-height: 1.5;
  text-align: center;
  color: $primary-dark-green;
  padding-bottom: 1.6rem;
}

.modal > .content {
  width: 100%;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 1.5;
  text-align: center;
  color: $primary-dark-green;
}

.close {
  cursor: pointer;
  position: absolute;
  display: block;
  padding: 0.2rem 0.5rem;
  line-height: 2rem;
  right: 1rem;
  top: 1rem;
  font-size: 2.4rem;
  background: #ffffff;
  border: none;
  appearance: none;
}

.icon {
  text-align: center;
  position: relative;
  margin-bottom: 2.4rem;

  &.square {
    transform: rotate(4deg);
    width: 125px;
    height: 119px;
    margin: auto;
    margin-bottom: 2.4rem;

    img {
      transform: rotate(-4deg);
      margin-top: -20px;
    }

    &::before {
      content: "";
      width: 125px;
      height: 119px;
      background: #194142;
      border-radius: 16px;
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      margin: auto;
      bottom: 0;
    }

    &::after {
      content: "";
      width: 125px;
      height: 20px;
      background: white;
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      border-radius: 16px;
      margin: auto;
      top: 100%;
      z-index: 10;
    }
  }

  img {
    height: auto;
  }
}

.modal::-webkit-scrollbar {
  display: none;  /* Safari and Chrome */
}

.modal::after {
  content: "";
  border-radius: 16px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 6rem;
  background: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.75), rgba(255, 255, 255, 1));
  pointer-events: none;
}

@media screen and (max-width: ($bp-xs)) {
  .modal {
    padding: 4rem 1.6rem 6rem;
  }
}
