@import "../../styles/variables.module";

.card_floating_img_wrapper {
  position: relative;
}

.floting_box {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding: 2.4rem 1.6rem;
  width: 17rem;
  min-height: 14rem;
  background: $light-green;
  border-radius: 1.6rem;
  text-align: center;
}

.content_box {
  background: $white;
  box-shadow: 0.4rem 0.4rem 1.6rem rgba(0, 0, 0, 0.08);
  border-radius: 1.6rem;
  padding: 3.2rem 1.6rem;
  margin-top: -1.6rem;
  margin-left: 2rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
}

.floating_box_image {
  padding-bottom: 1rem;
}

.floating_text {
  font-weight: 700;
  color: $primary-dark-green;
  font-size: 1.6rem;
  line-height: 1.5;
}

.content_box_text {
  flex: 1;
  padding-bottom: 2rem;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 1.5;
  color: $primary-dark-green;
}

.link {
  font-weight: 700;
  color: $primary-dark-green;
  position: relative;
  display: block;
  padding-right: 3.6rem;
  font-size: 1.6rem;
}

.link:after {
  content: "";
  position: absolute;
  background: url("/img/arrow-right.svg") no-repeat center;
  width: 2.4rem;
  height: 2.4rem;
  right: 0;
  transition: all 0.3s ease-in-out;
}

.link:hover:after {
  right: -0.5rem;
}
