@import "../variables.module";

.auth {
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  background: url("../../img/steps_images/login.png") no-repeat center;
  background-size: cover;

  &.reset_password {
    background: url("../../img/steps_images/password.png") no-repeat center;
    background-size: cover;
  }

  &.account {
    background: url("../../img/steps_images/account.png") no-repeat center;
    background-size: cover;
  }

  header {
    background: transparent !important;

    h1 {
      font-size: 0;
    }

    h1::before {
      background-image: url("../../img/logo_full_white.svg");
      width: 170px;
      height: 40px;
    }
  }

  @media screen and (min-width: ($bp-xs)) {
    header {
      display: none;
    }
  }

  > form {
    padding-bottom: 4rem;
  }

  .image {
    text-align: center;
    flex: 1;

    img {
      display: inline-block;
    }
  }

  @media screen and (max-width: ($bp-xs)) {
    .image {
      display: none;
    }
  }

  .textMailbox {
    flex: 1;
  }

  & .mainImage {
    margin-top: 12.8rem;
    margin-bottom: 6.5rem;
    flex: 1;

    img {
      width: 100%;
      max-width: 54rem;
      height: 100%;
    }
  }

  & .box {
    max-width: 68rem;
    margin: auto;
    background: white;
    position: absolute;
    inset: 0;
    padding: 20px;
    top: auto;
    border-radius: 48px 48px 0 0;
    padding-top: 64px;

    .button_login {
      margin-top: 20px;
      display: block;
    }
  }

  .button_wrapper {
    flex: 1;
  }

  .not_activated {
    padding-top: 24px;
    text-align: center;
    color: #194142;
    font-size: 12px;

    a {
      color: #94dddf;
    }
  }

  @media screen and (min-width: ($bp-xs)) {
    & .box {
      display: flex;
      flex-direction: column;
      max-width: 68rem;
      margin: auto;
      background: white;
      padding: 80px 104px;
      border-radius: 48px;
      position: absolute;
      top: 80px;
      right: 80px;
      left: auto;
      bottom: 80px;
      margin: auto;
    }
  }

  & .textMargin {
    margin-bottom: 3.3rem;

    @media screen and (max-width: $bp-xs) {
      margin-bottom: 2.4rem;
    }
  }

  & .errorText {
    margin: 1.6rem;
    text-align: center;
    color: $secondary-dark-pink;
  }

  & .warningText {
    text-align: center;
    font-size: 12px;
    margin-top: 5px;
    color: $secondary-dark-pink;
  }

  .not_exists {
    background: $red;
    padding: 16px;
    margin-top: 16px;
    border-radius: 8px;
    color: $primary-dark-green;

    a {
      font-weight: bold;
      text-decoration: underline;
    }
  }

  & .passwordConstraintsText {
    margin-top: 0.8rem;
    margin-bottom: 1.6rem;
    font-style: normal;
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 150%;
    text-align: center;
    color: $secondary-dark-pink;
  }

  .middle_wrapper {
    flex: 1;
    justify-content: center;
    display: flex;
    flex-direction: column;

    .mailboxImage {
      padding-bottom: 3.2rem;
    }
  }

  .box_bottom {
    padding-bottom: 5.5rem;
  }

  & .accountText {
    color: $secondary-dark-green;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 150%;
    text-align: center;

    @media screen and (min-width: $bp-xs) {
      max-width: 70%;
      margin: auto;
    }

    &.margin {
      margin-top: 2.8rem;
    }

    & a {
      color: $secondary-blue;
    }
  }

  .separator {
    overflow: hidden;
    text-align: center;
    margin: 3rem 0;

    &:before,
    &:after {
      background-color: #c6d0d0;
      content: "";
      display: inline-block;
      height: 1px;
      position: relative;
      vertical-align: middle;
      width: 50%;
    }

    &:before {
      right: 20px;
      margin-left: -50%;
    }

    &:after {
      left: 20px;
      margin-right: -50%;
    }
  }
}

@media screen and (max-width: $bp-xs) {
  .auth {
    & .mainImage {
      margin-top: 8rem;
      margin-bottom: 3rem;

      img {
        height: auto;
      }
    }
  }
}
