@import "../../styles/variables.module";

.hide_button {
  margin-top: 2rem;
  border-width: 0;
  border-bottom-width: 1px;
  color: $grey;
  background: $white;
  cursor: pointer;
}

.hide_button:hover {
  color: $black;
}