@import "../../styles/variables.module";

@mixin icon($name) {
  &:before {
    background-image: url("../../img/icons/FFFFFF/icon-#{$name}.svg");
  }

  &.active:before {
    background-image: url("../../img/icons/D1F178/icon-#{$name}.svg");
  }

  @media screen and (min-width: $bp-xs) {
    &:hover:before {
      background-image: url("../../img/icons/D1F178/icon-#{$name}.svg");
    }
  }
}

.nav {
  background-color: $primary-dark-green;
  width: 27rem;
  height: calc(100vh - 110px);

  a {
    display: block;
    color: $white;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 110%;
    align-items: center;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    &:before {
      display: block;
      content: "";
      z-index: 1;
      width: 2.4rem;
      height: 2.4rem;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }

    &:focus,
    &:active {
      outline: none;
      box-shadow: none;
    }

    &.active {
      color: $primary-lime-green;
    }

    &:not(.profile) span {
      position: absolute;
      font-size: 14px;
      line-height: 150%;
      color: $secondary-dark-green;
      background: #ffffff;
      box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.15);
      border-radius: 4px;
      left: 100%;
      padding: 3px 9px;
      display: none;
    }
  }

  .close {
    display: none;
    position: absolute;
    right: 3.2rem;
    top: 3.2rem;
    padding: 0;

    @media screen and (max-width: $bp-xs) {
      display: block;
    }
  }

  .divider {
    border-bottom: 1px solid $divider;
    margin: 3rem 0;
    width: 19rem;
  }

  .links_wrapper {
    display: flex;
    position: relative;
    padding: 1.6rem 0;
    justify-content: center;

    @media screen and (max-width: $bp-xs) {
      justify-content: flex-start;
    }

    a {
      padding-left: 0;
    }

    .image {
      display: flex;
    }

    .user_profile_image {
      border-radius: 50%;
      width: 3.2rem;
      height: 3.2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $secondary-blue;
      color: $secondary-dark-green;
      margin-right: 1.8rem;
      font-size: initial;
      object-fit: contain;
    }
  }

  .home {
    @include icon("home");
  }

  .loyalty {
    @include icon("loyalty");
  }

  .investments {
    @include icon("investments");
  }

  .benefits {
    @include icon("benefits");
  }

  .events {
    @include icon("events");
  }

  .news {
    @include icon("news");
  }

  .documents {
    @include icon("documents");
  }

  .profile {
    padding: 0;
    padding-left: 1.6rem;
    border: none;

    &::before {
      display: none !important;
    }
  }

  .help {
    @include icon("help");
  }

  .more {
    @include icon("more");
    cursor: pointer;
  }
}

@media screen and (min-width: ($bp-s + 1px)) {
  .nav {
    padding-bottom: 4rem;
    a {
      display: flex;
      padding: 2rem 1rem 2rem 4.5rem;
      transition: all 0.5s;
      font-weight: 700;

      &:before {
        margin-right: 1.8rem;
      }

      &:after {
        content: "";
        position: absolute;
        left: 0;
        height: 4rem;
        width: 0.8rem;
        background-color: $primary-lime-green;
        display: none;
      }

      &:hover,
      &:focus,
      &.active {
        color: $primary-lime-green;
        &:after {
          display: block;
        }
      }
    }

    h4 {
      padding-left: 4rem;
      color: $nav-green-light;
    }

    .divider {
      border-color: $secondary-blue;
      margin-left: auto;
      margin-right: auto;
    }

    .more {
      display: none;
    }

    .moreList {
      h3 {
        display: none;
      }
    }
    .links_wrapper {
      flex-direction: column;
      padding: 2rem 1rem 2rem 4rem;
      padding-left: 4rem;
    }
  }
}

@media (orientation: landscape) and (hover: none) and (pointer: coarse) {
  .nav {
    overflow-x: unset !important;
  }
}

@media screen and (max-width: $bp-s) {
  .nav {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 101;
    width: 100%;
    padding: 0;
    height: 8rem;

    @supports (-webkit-touch-callout: none) {
      height: 10rem;
      padding-bottom: 20px;
    }

    a {
      padding: 16px 0;
      color: $white;
      text-align: center;
      font-size: 1.2rem;
      font-weight: 400;
      text-transform: lowercase;
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex: 1;

      &:before {
        background-position: center;
        margin: 0rem auto 1rem auto;
      }
    }

    .investments {
      position: relative;
      top: -16px;
      padding-bottom: 0;
      flex: 1.1 1.1;

      &:after {
        display: block;
        content: " ";
        width: 70px;
        height: 70px;
        position: absolute;
        top: 1px;
        left: 50%;
        z-index: -1;
        transform: translateX(-50%);
        background-color: $primary-dark-green;
        border-radius: 50%;
      }

      &:before {
        position: relative;
        top: -4px;
        z-index: 2;
        width: 45px;
        height: 45px;
        border-radius: 50%;
        background-size: contain;
        background-image: url("../../img/investment_navbar.svg");
      }

      &.active:before {
        background-image: url("../../img/investment_navbar_active.svg");
      }

      @media screen and (min-width: $bp-xs) {
        &:hover:before {
          background-image: url("../../img/investment_navbar_active.svg");
        }
      }
    }

    .moreList {
      display: none;
      position: fixed;
      bottom: 8rem;
      left: 0;
      z-index: -2;
      height: calc(100% - 18rem);
      width: 100%;
      border-top-left-radius: 1.6rem;
      background-color: $secondary-dark-green;
      font-size: 1.6rem;
      padding: 3.2rem;

      @supports (-webkit-touch-callout: none) {
        bottom: 10rem;
      }

      &.open {
        display: block;
      }

      h3 {
        font-style: normal;
        font-weight: 700;
        font-size: 2.4rem;
        color: #d1f178;
        margin-top: 0;
      }

      h4 {
        color: $nav-green-light;
      }

      a {
        color: $white;
        display: flex;
        align-items: center;
        flex-direction: row;
        text-align: left;
        font-size: 1.6rem;
        font-weight: 700;
        text-transform: none;
        justify-content: flex-start;

        &:before {
          margin: 0 1rem 0 0;
          display: inline-block;
        }

        &:hover {
          color: $primary-lime-green;
        }
      }
    }
  }
}

@media (min-width: ($bp-s + 1)) and (max-width: $bp-m) {
  .nav {
    width: 12rem;

    a {
      font-size: 0;

      &:hover {
        span {
          display: block;
        }
      }
    }

    .divider {
      width: auto;
      margin-left: 3rem;
      margin-right: 3rem;
    }
  }
}

@media (max-height: $bp-xs) and (min-width: $bp-xs) {
  .nav {
    overflow-x: scroll;
  }
}

@media (max-height: $bp-mobile) and (max-width: $bp-s) {
  .nav {
    .moreList {
      height: calc(100% - 9rem);
      padding: 1.6rem;
      h3 {
        margin-bottom: 1.2rem;
      }

      a {
        padding: 1.2rem 1.6rem;
      }
      .links_wrapper {
        padding: 0;
      }
    }
  }
}
