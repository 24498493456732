@import "../../styles/variables.module";

.text {
  font-size: 1.6rem;
  color: $text;
}

.title {
  font-size: 1.6rem;
  color: $text;
  font-weight: 700;
  padding-top: 1rem;

  @media screen and (max-width: ($bp-xs)) {
    text-align: center;
  }
}

.benefit_block {
  background: $light-green;
  border-radius: 1.6rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3rem;

  @media screen and (max-width: ($bp-xs)) {
    justify-content: space-between;
    padding: 2.4rem;
  }
}

.img_position_right {
  flex-direction: row;
  justify-content: space-between;

  img {
    order: 2;
    margin-left: 2rem;
  }

  .text {
    order: 1;
  }
}

.img_position_left {
  flex-direction: row;
  justify-content: space-between;

  img {
    margin-right: 2rem;
  }
}

.link {
  display: inline-block;
  background: url("/img/arrow-right.svg") center right no-repeat;
  background-size: contain;
  margin-left: auto;
  padding-right: 3rem;
  font-weight: 700;
  font-size: 1.6rem;
  margin-top: 2rem;
}
