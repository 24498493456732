@import "../variables.module";

.profile {
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 45.6rem;
  display: flex;
  flex: 1;
  justify-content: center;

  & .header {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  & .itemTextDetail {
    margin-bottom: 2rem;
    white-space: pre-line;
  }
  & .itemTextDisclaimer {
    margin-top: 2rem;
    font-size: 1.4rem;
    font-style: italic;
  }

  & .image {
    margin-bottom: 1.6rem;

    & img {
      border-radius: 100%;
    }
  }

  .user_profile_image {
    border-radius: 50%;
    width: 10.9rem;
    height: 10.9rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    background-color: $secondary-blue;
    color: $secondary-dark-green;
    font-size: 4rem;
    object-fit: contain;
  }

  & .name {
    font-style: normal;
    font-weight: 700;
    font-size: 2.4rem;
    line-height: 110%;
    color: $text;
    margin-bottom: 0.5rem;
    text-align: center;
  }

  & .email {
    font-style: normal;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 150%;
    color: $text;
    text-align: center;
  }

  & .container {
    margin-top: auto;
    margin-bottom: auto;
  }

  & .partnerLabel {
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 150%;
    color: $dark-gray;
    font-style: italic;
  }

  & .helpSeparator {
    color: $secondary-blue;
    font-weight: 700;
    margin-top: 0.8rem;
    margin-bottom: 2.4rem;
  }

  & .settings {
    & .colouredBox,
    &.colouredBox {
      background: #f6fcfc;
      border-radius: 1.6rem;
    }

    & .itemClickable {
      cursor: pointer;
    }

    & .item {
      display: flex;
      align-items: center;
      border-bottom: 1px solid $blue2;
      padding: 2rem 0;
      margin: 0 2rem;

      & .itemIcon {
        display: flex;
      }

      & .itemText {
        display: inline-block;
        font-style: normal;
        font-weight: 700;
        font-size: 1.6rem;
        line-height: 110%;
        color: $text;
        margin-left: 2rem;
      }

      & .itemCheckbox {
        margin-left: auto;
      }
    }

    & .item:last-child {
      border-bottom: none;
    }
  }

  & .items {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-top: 0.8rem;
    & .dateSeparator {
      font-style: normal;
      font-weight: 700;
      font-size: 1.6rem;
      line-height: 150%;

      color: $secondary-blue;
    }

    & .itemWithBottomLine {
      border-bottom: 0.05rem solid $light-gray;
      padding-bottom: 1.6rem;
    }

    & .item {
      display: flex;
      margin-bottom: 3.6rem;
      align-items: center;
      cursor: pointer;

      & .itemIcon {
        padding-right: 1.6rem;
        display: flex;
      }

      & .itemCategory {
        color: $dark-gray;
        font-style: normal;
        font-weight: 700;
        font-size: 1.2rem;
        line-height: 110%;
        margin-bottom: 0.5rem;
      }

      & .itemText {
        font-style: normal;
        font-weight: 700;
        font-size: 1.6rem;
        line-height: 130%;
        color: $text;
      }

      & .itemDate {
        margin-top: 0.5rem;
        color: $dark-gray;
        font-style: normal;
        font-weight: 400;
        font-size: 1.2rem;
        line-height: 150%;
      }

      & .itemArrow {
        margin-left: auto;
        display: flex;
      }
    }
    &.menu {
      margin-top: 5.4rem;
    }
  }
}

.appVersion {
  display: block;
  text-align: center;
  font-size: 1rem;
  color: #999;
}

.no_documents {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h3 {
    font-size: 16px;
    line-height: 150%;
    padding-bottom: 130px;
    font-weight: 400;
    color: $primary-dark-green;
  }

  @media screen and (max-width: $bp-mobile) {
    h3 {
      padding-bottom: 50px;
    }

    img {
      width: 50%;
    }
  }
}

.language_setting {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 1.6rem;
  color: $primary-dark-green;
  padding-right: 1.6rem;
}
