@import "../../styles/variables.module";

.loyalty_log {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
  margin-bottom: 1.6rem;
}

.image {
  border-radius: 100%;
}

.detailsBox {
  margin-left: 1.6rem;
  flex: 1;
  padding-right: 10px;
}

.log_name {
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  color: $text;
}

.log_date {
  font-style: italic;
  font-weight: 400;
  font-size: 1.6rem;
  color: grey;
}

.number_uppies {
  display: inline-block;
  padding: 0.2rem 1.6rem;
  border-radius: 1.7rem;
  color: $text;
  font-weight: bold;
  margin-left: auto;

  &.plus {
    background: $green;
  }

  &.minus {
    background: $red;
  }

  &.waiting {
    background: $light-gray;
  }

  &.declined {
    background: $light-gray;
  }
}
