/* variables.module.scss */
$primary-dark-green: #194142;
$primary-lime-green: #d1f178;
$primary-blue: #71d1d4;

$secondary-dark-green: #537171;
$secondary-green: #0e594f;
$secondary-blue: #94dddf;
$secondary-dark-pink: #e86c70;
$lime-green-bg: #f3fcdd;
$lime-green-icon: #ddf49a;
$blue-bg: #dcf3f4;
$purple-bg: #ecdcf4;
$purple-icon: #c795de;
$lime-green-card: #e8f8bc;
$blue-card: #b8e8ea;
$light-blue-card: #f6fcfc;
$darker-blue-card: #cedada;
$purple-card: #dab9e9;
$light-green: #eef9fa;
$nav-green-light: #edf8c8;
$divider: #e3f6ad;

$green: #f3fcdd;
$red: #f9dadb;
$text: #194142;
$grey: #8ca0a1;
$blue: #71d1d4;
$blue2: #e5f6f7;
$secondary-purple: #ecdcf4;

$input: #f1f9f9;
$dark-gray: #8ca0a1;
$light-gray: #c6d0d0;
$white: #fff;
$black: #000;

/*breakpoints*/
$bp-mobile: 600px;
$bp-xs: 768px;
$bp-xs-mid: 992px;
$bp-s: 1024px;
$bp-m: 1280px;
$bp-l: 1920px;
