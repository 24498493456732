@import "../variables.module";

.cash_in_form {
  max-width: 45.6rem;
  margin: auto;
  .cash_in_radios {
    display: flex;
    flex-wrap: wrap;
    gap: 1.2rem;
    padding-bottom: 1.2rem;

    .form_type_radio {
      display: flex;
      width: calc(33.333% - 0.8rem);

      &:nth-child(4),
      &:nth-child(5),
      &:nth-child(6),
      &:nth-child(7) {
        width: calc(50% - 0.6rem);
      }

      label {
        padding: 1.6rem;
        border-radius: 0.8rem;
        background-color: #f6fcfc;
        width: 100%;
        max-width: none;
        text-align: center;
        cursor: pointer;
        border: solid 1px transparent;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: 1.6rem;

        span {
          color: $primary-dark-green;
          font-size: 1.6rem;
        }
        span:first-child {
          font-size: 2.4rem;
          font-weight: 700;
        }
      }
    }

    input {
      display: none;

      &:checked + label {
        border: solid 1px #ddf49a;
        background-color: #f3fcdd;
        box-shadow: 0.4rem 0.4rem 1.6rem rgba(0, 0, 0, 0.08);
      }

      &.error + label {
        border-color: $red;
      }
    }
  }

  .text {
    font-size: 1.6rem;
    line-height: 1.5;
    color: $primary-dark-green;
  }

  .voucher_value_text {
    font-size: 1.6rem;
    line-height: 1.5;
    color: $primary-dark-green;
    padding-top: 2.2rem;
    padding-bottom: 1.6rem;
  }
}

.new_lead_form {
  max-width: 45.6rem;
  margin: auto;

  .title {
    text-align: center;

    @media screen and (min-width: ($bp-xs)) {
      .title {
        margin-bottom: 15rem;
      }
    }
  }

  .intro {
    font-size: 1.6rem;
    line-height: 150%;
    color: $primary-dark-green;
  }

  .form_checkbox_privacy {
    display: flex;
    align-items: center;
    color: $primary-dark-green;
    margin: 2rem 0;

    input {
      appearance: none;
      width: 1.6rem;
      height: 1.6rem;
      border: 2px solid $primary-dark-green;
      border-radius: 0.4rem;

      &:checked {
        background: url("../../img/cross.svg") no-repeat center;
        background-size: 1rem;
      }
    }

    label {
      flex: 1;
      font-size: 1.2rem;
      line-height: 110%;
      padding-left: 2rem;
    }
  }
}

.error_message {
  color: $secondary-dark-pink;
  margin-top: 0;
  font-size: 1.6rem;
}

.error {
  border: 1px solid $secondary-dark-pink !important;
}

.submitSell {
  max-width: 45.4rem;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  height: 100%;
  display: flex;
  flex-direction: column;

  .title {
    text-align: center;
  }

  .container {
    margin-top: auto;
    margin-bottom: auto;
  }

  & .label {
    margin-bottom: 1.6rem;
    margin-top: 2.4rem;
    color: $blue;
    position: relative;
    overflow: hidden;
    padding-left: 1.6rem;

    &:before,
    &:after {
      position: absolute;
      top: 51%;
      overflow: hidden;
      width: calc(100% - 1.6rem - 0.8rem);
      height: 1px;
      content: "\a0";
      background-color: $blue;
    }

    &:before {
      width: 0.7rem;
      margin-left: -1.5rem;
      text-align: right;
    }

    &:after {
      margin-left: 0.8rem;
    }
  }
}
