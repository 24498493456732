@import "../../styles/variables.module";

.formBlock {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 1;
  background: $white;
  box-shadow: 0.4rem 0.4rem 1.6rem rgba(0, 0, 0, 0.08);
  border-radius: 1.6rem;
  padding: 1.6rem;
  cursor: pointer;

  .callToAction {
    display: flex;
    align-items: center;
    color: $primary-dark-green;
    position: relative;

    div:first-child {
      flex: 1;
      padding-right: 35px;
    }
  }

  .link {
    position: absolute;
    right: 0;
    display: block;
    width: 2.4rem;
    height: 2.4rem;
    background: url("/img/arrow-right.svg") center no-repeat;
    margin-left: auto;
    transition: all 0.3s ease-in-out;
  }

  &.img_position_left {
    flex-direction: row;
    align-items: center;
    .blockImage {
      img {
        margin-right: 2rem;
        height: 2.4rem;
        width: auto;
      }
    }
  }
}

.content_wrapper {
  flex: 1;
  width: 100%;
}

.formBlock:last-child {
  margin-right: 0;
}

@media screen and (max-width: ($bp-xs)) {
  .formBlock {
    flex-direction: row;

    .blockImage {
      img {
        margin-right: 2rem;
      }
    }
  }
}

@media screen and (min-width: ($bp-xs)) {
  .formBlock {
    &:hover {
      .link {
        right: -0.5rem;
      }
    }
  }
}
