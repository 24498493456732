@import "./variables.module";

html,
body {
  margin: 0;
  font-family: Roboto, sans-serif;
  overscroll-behavior: none;
  padding: calc(env(safe-area-inset-top) / 2) env(safe-area-inset-right)
    env(safe-area-inset-bottom) env(safe-area-inset-left);
}

html {
  font-size: 62.5%;
}

body {
  font-size: 1.6rem;
  line-height: 1.5;
}

#root {
  min-height: 75vh;
  display: flex;

  .main_wrapper {
    width: 100%;
    align-items: center;
    display: flex;
    flex-direction: column;
  }
}

main {
  position: relative;
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: $bp-xs) {
  main {
    width: auto;
    position: relative;
    padding-bottom: 12rem;
  }

  #root {
    //padding: 1.6rem 1.6rem 4rem 1.6rem;
    overflow-x: hidden;
  }
}

@media screen and (min-width: ($bp-xs+1px)) and (max-width: ($bp-s)) {
  main {
    padding-bottom: 10rem;
  }
}

@media screen and (min-width: ($bp-xs+1)) {
  main {
    margin: 0 auto;
    padding-left: 1.6rem;
    padding-right: 1.6rem;
    flex: 1;
  }
}
@media screen and (min-width: ($bp-s+1)) {
  main {
    padding-bottom: 4rem;
    width: calc(100% - 27rem);
    max-width: 93.6rem;
  }
}

a {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}

* {
  box-sizing: border-box;
}

h1 {
  font-size: 1.6rem;
  font-weight: 700;
  color: $primary-dark-green;
}

@media screen and (min-width: ($bp-xs+1)) {
  #root {
    display: flex;
  }
}

/*
@media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
  body {
    color: white;
    background: black;
  }
} */
