@import "../../styles/variables.module";

.story {
  padding: 0.8rem;
  position: relative;
  border-radius: 1.6rem;
  box-shadow: 0.4rem 0.4rem 1.6rem rgba(0, 0, 0, 0.08);
  height: 100%;
  display: flex;
  flex-direction: column;

  h3 {
    display: block;
    margin: 0;
    color: $blue;
    font-size: 1.6rem;
    font-weight: 700;
    line-height: 1.5;
    cursor: pointer;
  }

  .body {
    flex: 1;

    ul {
      padding-left: 15px;
    }
  }
}

.img_wrapper {
  img {
    border-radius: 1.6rem;
    width: 100%;
    object-fit: cover;
  }
}

.badge {
  position: absolute;
  top: 1.6rem;
  left: 1.6rem;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.4rem 0.8rem;
  border-radius: 2.4rem;
  font-size: 1.6rem;
  font-weight: 700;
  background-color: $primary-lime-green;
}

.badge_blog,
.story_badge,
.badge_under_construction {
  background-color: $primary-lime-green;
}

.badge_in-construction,
.badge_in-constructie {
  background-color: $blue-bg;
}

.content_wrapper {
  align-items: flex-start;
  padding: 1.6rem;
  display: flex;
  flex-direction: column;
  flex: 1;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 0.8rem;
  }
}

.date {
  display: block;
  color: $secondary-dark-green;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.5;
  margin-bottom: 0.8rem;
}

.link {
  color: $primary-dark-green;
  font-size: 1.6rem;
  font-weight: 700;
  position: relative;
  display: block;
  padding-right: 3.6rem;
  margin-top: 2rem;
  cursor: pointer;
}

.link:after {
  content: "";
  position: absolute;
  background: url("/img/arrow-right.svg") no-repeat center;
  width: 2.4rem;
  height: 2.4rem;
  margin-left: 10px;
  transition: all 0.3s ease-in-out;
}

@media screen and (min-width: ($bp-mobile+1)) and (max-width: ($bp-xs)) {
  .story {
    &.slider {
      height: 100%;
      display: flex;
      flex-direction: column;
    }

    .img_wrapper {
      flex: none;
    }

    .content_wrapper {
      flex: 1;
      display: flex;
      flex-direction: column;

      .body {
        flex: 1;
      }
    }
  }
}

@media (min-width: ($bp-xs + 1)) {
  .story {
    display: flex;
    flex-direction: row;
    height: auto;
  }

  .link:after {
    right: 0;
    margin-left: 0;
  }

  .content_wrapper {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 1.6rem 3.2rem;
  }

  .link:hover:after {
    right: -0.5rem;
  }

  .img_wrapper {
    flex: 1;
    img {
      height: 100%;
    }
  }

  .body {
    flex: 1;
    padding-top: 0.8rem;
    padding-bottom: 1.6rem;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 1.5;
    color: $primary-dark-green;

    ul {
      padding-left: 15px;
    }
  }
}
