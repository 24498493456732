@import "../variables.module";

h1.title {
  font-weight: 700;
  font-size: 3.2rem;
  line-height: 1.1;
  color: $primary-dark-green;
}

.card_floating_img_cards {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 4rem 2rem;

  & > div {
    flex: 100%;
    display: flex;
    flex-direction: column;
  }
}

@media screen and (min-width: $bp-xs-mid) {
  .card_floating_img_cards {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 2rem;
    gap: 4rem 2rem;

    & > div {
      flex: 0 0 calc(50% - 1rem);
      display: flex;
      flex-direction: column;
    }
  }
}
