@import "../../styles/variables.module";

.onboarding_wrapper {
  max-width: 68rem;
  margin: auto;
  background: white;
  position: absolute;
  inset: 0;
  padding: 20px;

  > div {
    height: 100%;
  }
}

@media screen and (min-width: ($bp-xs)) {
  .onboarding_wrapper {
    max-width: 68rem;
    margin: auto;
    background: white;
    padding: 80px 104px;
    border-radius: 48px;
    position: absolute;
    top: 30px;
    right: 80px;
    left: auto;
    bottom: 30px;
    margin: auto;
  }
}

@media (max-height: $bp-xs-mid){
  .onboarding_wrapper {
    padding: 50px 104px;
  }
}

.title {
  font-weight: 700;
  font-size: 4rem;
  line-height: 1.1;
  display: flex;
  align-items: center;
  color: $primary-dark-green;
  padding-bottom: 1.6rem;
  padding-top: 4rem;
}

.body {
  font-size: 1.6rem;
  line-height: 1.5;
  color: $primary-dark-green;
  padding-bottom: 4rem;
  flex: 1;
}

.image {
  text-align: center;
  flex: 1;

  img {
    display: inline-block;
  }
}

.onboarding_step_one {
  height: 100%;
  display: flex !important;
  flex-direction: column;
}

.onboarding_step_two {
  height: 100%;
  display: flex !important;
  flex-direction: column;

  .image,
  .body {
    flex: unset;
  }
}

.action_wrapper {
  font-size: 1.6rem;
  line-height: 1.5;
  font-weight: 700;
  color: $primary-dark-green;
  cursor: pointer;
  height: 48px;
  margin-top: 50px;
  margin-bottom: 20px;

  .primary_button {
    background: $blue;
    border-radius: 0.8rem;
    font-size: 1.6rem;
    line-height: 1.1;
    text-align: center;
    color: $primary-dark-green;
    display: block;
    padding: 1.6rem;
    cursor: pointer;
  }
}

.custom_next {
  position: relative;
  margin-bottom: 20px;

  button {
    appearance: none;
    border: none;
    width: 100%;
    height: 48px;
    font-size: 1.6rem;
    line-height: 1.1;
    text-align: center;
    color: #194142;
    font-weight: bold;
    border-radius: 8px;
    background: #71d1d4;
  }

  img {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
  }
}

.profile {
  flex: 1;

  .settings {
    background: #eef9fa;
    border-radius: 1.6rem;

    .item {
      display: flex;
      align-items: center;
      border-bottom: 1px solid $blue2;
      padding: 1.1rem 0;
      margin: 0 2rem;

      & .itemIcon {
        display: flex;
      }

      & .itemText {
        display: inline-block;
        font-style: normal;
        font-weight: 700;
        font-size: 1.6rem;
        line-height: 110%;
        color: $text;
        margin-left: 2rem;
      }

      & .itemCheckbox {
        margin-left: auto;
      }
    }

    & .item:last-child {
      border-bottom: none;
    }
  }
}

@media screen and (max-width: ($bp-xs)) {
  .title {
    font-size: 3.2rem;
  }

  .body {
    font-size: 1.6rem;
  }
}

@media (orientation: landscape) and (hover: none) and (pointer: coarse) {
  .image {
    img {
      max-height: 250px;
      width: auto;
      margin: auto;
    }
  }

  .title {
    font-size: 3.2rem;
  }
}
