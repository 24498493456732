@import "../variables.module";

.invest {
  & .image {
    width: 100%;
    position: relative;
    height: 100%;
    margin-bottom: 2.4rem;
    border-radius: 0.8rem;
    object-fit: cover;

    @media screen and (min-width: $bp-xs) {
      height: 57rem;
    }
  }
}

.waardering {
  width: 100%;
}

.benefit_block {
  margin-top: 5rem;
  background: $light-green;
  border-radius: 1.6rem;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  padding: 3rem;

  img {
    order: 2;
    margin-left: 2rem;
  }

  .text {
    order: 1;
    font-size: 1.6rem;
    color: $text;
  }

  .link {
    display: inline-block;
    background: url("/img/arrow-right.svg") center right no-repeat;
    background-size: contain;
    margin-left: auto;
    padding-right: 3rem;
    font-weight: 700;
    font-size: 1.6rem;
    margin-top: 2rem;
  }
}

.numbers {
  display: flex;
  width: 100%;
  gap: 2rem;
  flex-wrap: wrap;

  & > div {
    flex: 1;
  }
}

.sell_wrapper {
  padding: 5rem 0;
}

.investment_day_wrapper {
  display: flex;
  flex-wrap: wrap;

  .investment_day {
    font-size: 2.4rem;
    line-height: 150%;
    color: $primary-dark-green;
    padding-bottom: 2rem;
    width: 100%;
  }

  .investment_day_expire {
    display: inline-block;
    background-color: #c6ebed;
    padding: 0.8rem 1.2rem 0.8rem 3.6rem;
    font-size: 1.6rem;
    line-height: 150%;
    color: $primary-dark-green;
    border-radius: 2.4rem;
    margin-bottom: 3rem;
    background-image: url("../../img/icons/D1F178/icon-lock.svg");
    background-repeat: no-repeat;
    background-position: left 1rem center;
    background-size: 2rem;
  }
}

.title {
  font-style: normal;
  font-weight: 700;
  font-size: 3.2rem;
  line-height: 1.3;
  padding-bottom: 1.6rem;
}

.date {
  font-style: normal;
  font-weight: 400;
  font-size: 2.4rem;
  line-height: 1.5;
}

.date_small {
  line-height: 1.5;
  font-size: 1.6rem;
  padding-bottom: 2rem;
}

.increase_value_block {
  background: $white;
  border-radius: 0.8rem;
  filter: drop-shadow(0.4rem 0.4rem 1.6rem rgba(0, 0, 0, 0.08));
  font-style: normal;
  font-weight: 700;
  font-size: 4rem;
  line-height: 1.1;
  display: block;
  text-align: center;
  color: $secondary-green;
  padding: 3.6rem 0;
  margin: 3rem 0;
}

.link {
  display: inline-block;
  background: url("/img/arrow-right.svg") center right no-repeat;
  background-size: contain;
  margin-left: auto;
  padding-right: 3rem;
  font-weight: 700;
  font-size: 1.6rem;
}

@media screen and (min-width: $bp-m) {
  .investment_data_wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 0 25px;

    .image {
      width: 50%;
      margin-bottom: 0;
      height: 49rem;
    }

    .numbers {
      flex: 1;
      flex-direction: column;
    }

    .sell_wrapper {
      width: 100%;
    }
  }
}
