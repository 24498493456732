@import "../../styles/variables.module";

.grow {
  flex-basis: calc(50% - 1rem);
}

.gift_block_wrapper {
  width: 100%;
  position: relative;
  height: 100%;
}

.giftBlock {
  display: flex;
  padding: 1.6rem;
  flex: 1 1;
  gap: 1.6rem;
  background: $white;
  box-shadow: 0.4rem 0.4rem 1.6rem rgba(0, 0, 0, 0.08);
  border-radius: 1.6rem;
  height: 100%;

  & img {
    height: 100%;
    border-radius: 16px;
    object-fit: cover;
  }
}

.giftText {
  font-style: italic;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.5;
  color: $secondary-dark-green;
  flex: 1;
}

.right_wrapper {
  display: flex;
  flex-direction: column;
}

.tooltip_wrapper {
  right: 8px;
  top: 8px;
  position: absolute;
}

.tooltip_text {
  display: none;
  position: absolute;
  background: $blue-card;
  padding: 8px;
  font-size: 10px;
  line-height: 15px;
  font-style: italic;
  min-width: 200px;
  right: 0;
  border-radius: 4px;
  top: 104%;

  &.active {
    display: block;
  }

  &:before {
    content: "";
    display: block;
    width: 0;
    height: 0;
    border-right: 12px solid transparent;
    border-left: 12px solid transparent;
    border-bottom: 12px solid $blue-card;
    position: absolute;
    right: 2px;
    bottom: 96%;
  }
}

.giftBlock:last-child {
  margin-right: 0;
}

.giftLabel {
  color: $blue;
  font-weight: 700;
  font-size: 1.6rem;
  line-height: 1.5;
  width: 100%;
  margin-bottom: 0.5rem;
  padding-right: 30px;
  cursor: pointer;
}

.link {
  display: inline;
  background: url("/img/arrow-right.svg") center right no-repeat;
  background-size: contain;
  padding-right: 3.5rem;
  font-weight: 700;
  font-size: 1.6rem;
  transition: all 0.3s ease-in-out;
  align-self: flex-start;
  cursor: pointer;
}

.link:hover {
  padding-right: 4rem !important;
}

@media screen and (max-width: $bp-mobile) {
  .giftBlock {
    & img {
      width: 127px;
      height: 152px;
    }
  }

  .grow {
    flex-basis: 100%;
  }
}

@media screen and (max-width: $bp-xs) {
  .grow {
    flex-basis: 100%;
  }
}
