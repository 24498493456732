@import "../../styles/variables.module";

.cardIconBox {
  background: $white;
  box-shadow: 0.4rem 0.4rem 2.4rem rgba(0, 0, 0, 0.08);
  border-radius: 1.6rem;
  display: flex;
  position: relative;
  flex: 1 1;
  padding: 2.4rem;
  color: $primary-dark-green;

  &.margin {
    margin: 0 2.4rem;
  }

  & .title {
    font-style: normal;
    font-weight: 700;
    font-size: 2.4rem;
    line-height: 111%;
    text-align: center;
    padding-top: 2.7rem;
    padding-bottom: 2.4rem;
    margin-top: auto;
    margin-bottom: auto;
  }

  & .content_wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;

    & .condition {
      margin-bottom: 1.6rem;
      display: flex;

      & .icon {
        margin-right: 1.6rem;
        width: 2rem;
        height: 2rem;
      }

      & .text {
        font-style: normal;
        font-weight: 400;
        font-size: 1.6rem;
        line-height: 150%;
      }
    }

    & .link {
      font-style: normal;
      font-weight: 700;
      font-size: 1.6rem;
      line-height: 110%;
      display: flex;
      align-items: center;
      cursor: pointer;

      & div {
        margin-right: 0.8rem;
        margin-left: 3.8rem;
      }
    }
  }

  & .image {
    border-radius: 100%;
    width: 6.4rem;
    height: 6.4rem;
    padding: 1.2rem;
    position: absolute;
    left: calc(50% - 3.2rem);
    margin-top: -5.4rem;

    &.green {
      background-color: $lime-green-card;
    }

    &.blue {
      background-color: $blue-card;
    }

    &.purple {
      background-color: $purple-card;
    }
  }
}
