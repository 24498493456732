@import "../variables.module";

.loyalty {
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 150%;
  color: $primary-dark-green;
}

.displayFlex {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 2rem;
  gap: 2rem;
}

.cash_uppies_text {
  padding-bottom: 3.2rem;
}

@media screen and (min-width: $bp-xs-mid) {
  .displayFlex {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 2rem;
    gap: 2rem;

    & > div {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 16px 24px;
    }

    &.giftCardsWrapper {
      & > div {
        padding: 0;
      }
    }
  }

  .link:hover {
    padding-right: 3.5rem !important;
  }
}

.benefit_block {
  margin-top: 5rem;
  background: $light-green;
  border-radius: 1.6rem;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  padding: 3rem;

  img {
    order: 2;
    margin-left: 2rem;
  }

  .text {
    order: 1;
    font-size: 1.6rem;
    color: $text;
  }
  .title {
    font-size: 1.6rem;
    color: $text;
    font-weight: 700;
  }

  .link {
    display: inline-block;
    background: url("/img/arrow-right.svg") center right no-repeat;
    background-size: contain;
    margin-left: auto;
    padding-right: 3rem;
    font-weight: 700;
    font-size: 1.6rem;
    margin-top: 2rem;
    transition: all 0.3s ease-in-out;
  }
}
