@import "../../styles/variables.module";

#header {
  position: relative;
  width: 27rem;
  max-height: 120px;

  &.fullwidth {
    width: 100%;
    z-index: 100;
    background-color: white;
  }

  &.fullwidth h1 {
    color: $primary-dark-green;
    padding: 2rem;
    margin: 0;
  }

  .header_onboarding {
    width: 100% !important;
  }
}

.return {
  display: block;
  position: absolute;
  top: 0;
  width: 5rem;
  height: 100%;
  margin: 0;
  font-size: 0;
  background-size: 2rem;
  background-repeat: no-repeat;
  background-position: left center;
  background-image: url(/img/icons/194142/icon-arrow.svg);
  appearance: none;
  outline: none;
  background-color: transparent;
  border: none;

  &.return_anon {
    background-position: center;
  }
}

.notifications {
  //display: block;
  position: absolute;
  right: 0;
  top: 0;
  width: 2.5rem;
  height: 100%;
}

.h1 {
  vertical-align: middle;
  text-align: center;

  &::before {
    content: " ";
    display: inline-block;
    vertical-align: middle;
    height: 3rem;
    width: 4rem;
    margin-right: 1rem;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url(/img/logo.svg);
  }
}

@media screen and (max-width: ($bp-xs)) {
  #header {
    width: 100%;
  }
}

@media screen and (min-width: ($bp-xs + 1px)) and (max-width: ($bp-s)) {
  #header {
    width: calc(100% - 27rem);
    max-width: 93.6rem;
    margin: 0 auto;
  }
}

@media screen and (min-width: ($bp-s + 1px)) {
  .h1 {
    color: $white;
    padding: 4rem 3rem;
    margin: 0;
  }

  .header_onboarding {
    width: 100% !important;

    .h1 {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 2rem;
      color: $primary-dark-green;

      &:before {
        margin-right: 1rem;
      }
    }
  }

  .notifications {
    display: none;
  }
}

@media (min-width: ($bp-s + 1px)) and (max-width: $bp-m) {
  #header {
    width: 12rem;
  }

  .h1 {
    display: flex;
    font-size: 0;
    justify-content: center;

    &:before {
      margin: 0;
    }
  }

  .fullwidth h1 {
    align-items: center;

    &:before {
      margin-right: 1rem;
    }
  }
}
