@import "../variables.module";

.benefits_blocks {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 2rem;

  & > div {
    flex: 0 0 calc(50% - 1rem);
  }
}

.numbers {
  display: flex;
  width: 100%;
  gap: 2rem;
  flex-wrap: wrap;

  & > div {
    flex: 1;
  }
}

.projects {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 2rem;
  gap: 1.6rem;

  & > div {
    flex: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 6rem;
  }
}

.more {
  display: flex;
  width: 100%;
  align-items: baseline;

  & .right {
    display: flex;
    align-items: center;
    margin-left: auto;

    & .text {
      margin-right: 1.3rem;
      color: $text;
      font-style: normal;
      font-weight: 400;
      font-size: 1.6rem;
      line-height: 150%;
      cursor: pointer;
    }

    & img {
      width: 0.7rem;
      height: 1.3rem;
    }
  }
}

.slick_outer_wrapper {
  margin: -2rem;
  overflow: hidden;
  padding-bottom: 3rem;

  .slick-slide {
    padding: 2rem;
  }
}

@media screen and (min-width: $bp-xs) {
  .projects {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    gap: 2rem;
  }
}

@media screen and (min-width: $bp-s) {
  .benefits_blocks {
    flex-direction: row;
  }

  .projects {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    gap: 2rem;

    & > div {
      flex: 0 0 calc(50% - 1rem);
    }
  }
}

@media screen and (max-width: ($bp-xs)) {
  .projects {
    & > div {
      margin-top: 0;
      flex-direction: row;
      padding: 0.8rem;
    }
  }

  .more {
    flex-direction: column;
    padding-bottom: 2rem;

    & .right {
      margin-left: 0;
    }
  }
}
