@import "../../styles/variables.module";

.block {
  border-radius: 1.6rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1.6rem;

  &.margin {
    margin: 0 2.4rem;
  }

  &.marginLeft {
    margin-left: 2.4rem;
  }

  &.marginRight {
    margin-right: 2.4rem;
  }

  &.single_block {
    flex-direction: row;
    justify-content: flex-start;

    @media (min-width: $bp-m) {
      flex-direction: column;
      justify-content: center;
    }

    .icon {
      margin-right: 3rem;
      @media (min-width: $bp-m) {
        margin: 0;
      }
    }

    .content_wrapper {
      align-items: flex-start;

      @media (min-width: $bp-m) {
        align-items: center;
      }
    }
  }

  .content_wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
}

.number {
  margin-top: 0.8rem;
  color: $primary-dark-green;
  font-size: 2.4rem;
  font-weight: 700;
  line-height: 1.1;
}

.unit {
  color: $primary-dark-green;
  font-size: 1.6rem;
  line-height: 1.5;
  text-align: center;
}

.subunit {
  font-style: italic;
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 150%;
  color: $grey;
}

.iconGreen {
  background-color: $lime-green-icon;
}

.iconBlue {
  background-color: $primary-blue;
}

.iconPurple {
  background-color: $purple-icon;
}

.bgGreen {
  background-color: $lime-green-bg;
}

.bgBlue {
  background-color: $blue-bg;
}

.bgPurple {
  background-color: $purple-bg;
}
