@import "../../styles/variables.module";

.owner_wrapper {
  display: flex;
  align-items: center;
  background: #ffffff;
  box-shadow: 0.4rem 0.4rem 1.6rem rgba(0, 0, 0, 0.08);
  border-radius: 1.6rem;
  min-height: 15rem;
  position: relative;
  margin: 5rem 0 0 0;

  &:before {
    content: " ";
    display: inline-block;
    width: 13.8rem;
    height: 12.4rem;
    position: absolute;
    bottom: 0;
    left: 0;
    vertical-align: middle;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url(/img/owner_left.svg);
  }

  &.hide_right_box {
    &:after {
      display: none;
    }
  }

  // BIGGER IMAGE
  &.picture_bigger {
    .left_wrapper {
      img {
        position: absolute;
        bottom: 0;
        left: 2.2rem;
        object-fit: cover;
        object-position: top;
        width: 17rem;
      }
    }

    .right_wrapper {
      padding-left: 21rem;
    }
  }
}

.left_wrapper {
  img {
    position: absolute;
    bottom: 0;
    left: 2.2rem;
    width: auto;
  }
}

.right_wrapper {
  padding-left: 16rem;
  flex: 1;
  font-size: 1.6rem;
  color: $primary-dark-green;

  .role {
    font-weight: 700;
    line-height: 1.1;
  }

  .name {
    line-height: 1.5;
  }

  .phone,
  .mail {
    border-radius: 3.1rem;
    padding: 0.6rem 0.8rem;
    margin-right: 0.8rem;
    display: flex;
    align-items: center;
    &:before {
      content: " ";
      display: block;
      height: 2rem;
      width: 2rem;
      margin-right: 0.8rem;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }
    &.hide {
      display: none;
    }
  }

  .phone {
    background: #e8f8bc;
    &:before {
      background-image: url(/img/owner_phone.svg);
    }
  }

  .mail {
    background: #b8e8ea;
    &:before {
      background-image: url(/img/owner_mail.svg);
    }
  }

  .contact_wrapper {
    padding-top: 1.6rem;
    font-weight: 700;
    line-height: 110%;
    display: flex;
  }
}

@media screen and (max-width: ($bp-mobile)) {
  .owner_wrapper {
    min-height: 11rem;
    &:before {
      width: 9.4rem;
      height: 8.4rem;
    }

    &:after {
      display: none;
    }
  }
  .left_wrapper {
    img {
      position: absolute;
      bottom: 0;
      left: 1.6rem;
      width: 9rem !important;
      height: auto;
      object-fit: cover;
    }
  }

  .right_wrapper {
    padding-left: 12.5rem !important;

    .mail {
      font-size: 0;
      &:before {
        margin: 0;
      }
    }
  }
  .phone_number {
    display: none;
  }
}

@media screen and (min-width: ($bp-mobile+1px)) {
  .owner_wrapper {
    .right_wrapper {
      .phone,
      .mail {
        padding: 0.8rem;
        margin-right: 1.6rem;
      }
    }
  }
  .phone_text {
    display: none;
  }
}

@media screen and (min-width: ($bp-s+1px)) {
  .owner_wrapper {
    &:after {
      content: " ";
      display: inline-block;
      width: 17.6rem;
      height: 15.2rem;
      position: absolute;
      top: 0;
      right: 0;
      vertical-align: middle;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      background-image: url(/img/owner_right.svg);
    }
  }
}

.owner_wrapper.hide_right_box {
  .right_wrapper {
    .mail {
      font-size: 0;
      &:before {
        margin: 0;
      }
    }
  }
}
