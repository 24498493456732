@import "../../styles/variables.module";

.main {
  display: flex;
  height: calc(100vh - 6.4rem);
  background-color: white;
}

.main_narrow {
  flex: 1;
}

.outer_wrapper {
  flex: 1;
  width: 100%;
  display: flex;
  overflow-x: hidden;

  &.outer_wrapper_onboarding {
    display: block;
    background: url("../../img/steps_images/login.png") no-repeat center;
    background-size: cover;
  }
}

.main > section {
  padding: 3.2rem;
}

.notifications {
  //display: block;
  position: absolute;
  right: 1.6rem;
  top: 4rem;
  display: none;
}

.return {
  display: none;
  position: absolute;
  top: 4rem;
  width: 5rem;
  height: 2rem;
  margin: 0;
  font-size: 0;
  background-size: 2rem;
  background-repeat: no-repeat;
  background-position: left center;
  background-image: url(/img/icons/194142/icon-arrow.svg);
  appearance: none;
  outline: none;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.navbar_wrapper {
  width: 27rem;
  border-radius: 0 1.6rem 1.6rem 0;
  position: relative;
  z-index: 2;

  > div {
    background-color: $primary-dark-green;
    border-radius: 0 1.6rem 1.6rem 0;
    overflow: auto;
    padding: 0;
  }
}

@media screen and (min-width: ($bp-s + 1)) {
  .navbar_wrapper {
    & > div {
      position: fixed;
      top: 0;
    }
  }
  .notifications {
    display: block;
  }

  .return {
    display: block;
  }
}

@media screen and (max-width: ($bp-s)) {
  .navbar_wrapper {
    background-color: transparent;
    position: relative;
    z-index: 2;
    width: 100%;

    & > div {
      position: relative;
      background-color: transparent;
      padding: 0;
      margin-top: 10px;
    }
  }

  .outer_wrapper {
    display: block;
    overflow-x: unset;
    padding: 0 20px;
  }

  .navbar_inner_wrapper {
    width: 100%;
  }

  #header {
    width: 100%;
  }
}

@media screen and (min-width: ($bp-s + 1)) and (max-width: $bp-m) {
  .navbar_wrapper {
    width: 12rem;

    & > div {
      overflow: visible;
    }
  }
}

@media screen and (min-width: $bp-m) {
  .navbar_wrapper {
    & > div {
      min-height: 100vh;
    }
  }
}
