@import "../variables.module";

.view {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 2rem;
  gap: 2rem;

  & > div {
    flex: 100%;
    display: flex;
    flex-direction: column;
  }
}

@media screen and (min-width: $bp-mobile) {
  .view {
    gap: 4rem 2rem;

    & > div {
      flex: 0 0 calc(50% - 1rem);
      display: flex;
      flex-direction: column;
    }
  }
}

@media screen and (min-width: $bp-s) {
  .view {
    & > div {
      display: flex;
      flex-direction: column;
    }
  }
}

@media screen and (max-width: ($bp-xs)) {
  .view {
    margin-top: 0;
  }
}
