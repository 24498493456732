@import "../variables.module";

.sell {
  & .options {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 6.1rem;
    gap: 0 2rem;

    > div {
      margin-top: 6.1rem;

      &:first-child {
        margin-top: 0;
      }
    }
  }

  .subtitle {
    font-size: 1.6rem;
    line-height: 1.5;
    color: $primary-dark-green;
  }

  .benefit_block {
    margin-top: 5rem;
    background: $light-green;
    border-radius: 1.6rem;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    padding: 3rem;

    img {
      order: 2;
      margin-left: 2rem;
    }

    .text {
      order: 1;
      font-size: 1.6rem;
      color: $text;
    }

    .link {
      display: inline-block;
      background: url("/img/arrow-right.svg") center right no-repeat;
      background-size: contain;
      margin-left: auto;
      padding-right: 3rem;
      font-weight: 700;
      font-size: 1.6rem;
      margin-top: 2rem;
    }
  }
}
.movable {
  padding-top: 10px;
  font-size: 14px;
  color: $primary-dark-green;
}

@media screen and (min-width: $bp-xs-mid) {
  .sell {
    & .options {
      display: flex;
      flex-direction: row;
      width: 100%;
      margin-top: 6.1rem;

      > div {
        margin-top: 0;
      }
    }

    .subtitle {
      font-size: 2rem;
      line-height: 1.5;
      max-width: 80%;
    }
  }
}
