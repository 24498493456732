.password {
  display: flex;
  position: relative;
  align-items: center;

  & .eyeToggle {
    position: absolute;
    cursor: pointer;
    right: 2.4rem;
  }
}
