@import "./variables.module";

.alignTextCenter {
  text-align: center;
}

.authTitle {
  color: $primary-dark-green;
  font-weight: 700;
  font-size: 3.2rem;
  line-height: 110%;
  margin-bottom: 2.4rem;

  &.small {
    font-size: 3.2rem;
  }

  @media screen and (max-width: $bp-xs) {
    font-size: 2.4rem;
    margin-bottom: 1.6rem;
  }
}

.authText {
  color: $primary-dark-green;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 150%;

  @media screen and (max-width: $bp-xs) {
    font-size: 1.6rem;
    margin-bottom: 2.4rem;
  }
}

.label {
  margin-bottom: 1.6rem;
  margin-top: 2.4rem;
  color: $blue;
  position: relative;
  overflow: hidden;
  padding-left: 1.6rem;

  &:before,
  &:after {
    position: absolute;
    top: 51%;
    overflow: hidden;
    width: calc(100% - 1.6rem - 0.8rem);
    height: 1px;
    content: "\a0";
    background-color: $blue;
  }

  &:before {
    width: 0.7rem;
    margin-left: -1.5rem;
    text-align: right;
  }

  &:after {
    margin-left: 0.8rem;
  }
}

.input {
  border-radius: 0.8rem;
  background-color: $input;
  padding: 1.2rem 1.6rem;
  border: none;
  width: 100%;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 150%;
  margin-bottom: 0.8rem;
  font-family: Roboto, sans-serif;
  appearance: none;
  color: inherit;
  min-height: 48px;

  &::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: $primary-dark-green;
    opacity: 1; /* Firefox */
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: $primary-dark-green;
  }

  &::-ms-input-placeholder {
    /* Microsoft Edge */
    color: $primary-dark-green;
  }

  &::-webkit-date-and-time-value {
    text-align: left;
  }

  &:disabled {
    opacity: 0.5;
  }
}

.button_modal,
.button {
  border-radius: 0.8rem;
  background: $primary-blue;
  width: 100%;
  border: none;
  font-weight: 700;
  font-size: 1.6rem;
  line-height: 110%;
  text-align: center;
  color: $primary-dark-green;
  padding: 1.6rem;
  cursor: pointer;
  margin-top: 2rem;

  &:hover {
    background: $secondary-dark-green;
  }

  &.green {
    background: $primary-lime-green;
  }

  &.red {
    background: $secondary-dark-pink;
    color: white;

    &:hover {
      background: $red;
    }
  }

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
    cursor: not-allowed;
  }
}

.button_secondary {
  border: 1px solid $primary-dark-green;
  background: white;
}

.button_modal {
  margin-top: 0;
}

.spinner {
  display: block;
  margin: auto;
  background: url("../img/loading_icon.svg") no-repeat center;
  background-size: cover;
  width: 2rem;
  height: 2rem;
  animation-name: spin;
  animation-duration: 3000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.title {
  font-style: normal;
  font-weight: 700;
  font-size: 2.4rem;
  line-height: 110%;
  color: $primary-dark-green;
  margin-top: 4rem;
  margin-bottom: 2.4rem;

  &.lessMargin {
    margin-bottom: 2.4rem;
  }

  &.title_narrow {
    display: none;
    @media screen and (min-width: $bp-xs) {
      display: block;
    }
  }

  &.noMargin {
    margin: 0;
  }

  &.topMargin {
    margin-top: 7.2rem;

    @media screen and (max-width: $bp-xs) {
      margin-top: 3.6rem;
    }
  }

  &.align_center {
    text-align: center;

    @media screen and (max-width: $bp-xs) {
      text-align: left;
    }
  }
}

.text {
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 150%;
  color: $primary-dark-green;
}

.global_text {
  max-width: 670px;
}

@media screen and (max-width: ($bp-xs)) {
  .title {
    margin-bottom: 1.6rem;
    margin-top: 3.6rem;
  }
}
