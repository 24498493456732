@import "../../styles/variables.module";

.numbers {
  display: flex;
  width: 100%;

  & > div {
    flex: 1;
    margin: 0.4rem;
  }
}

.projects {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 2rem;
  gap: 2rem;

  & > div {
    flex: 0 0 calc(50% - 1rem);
    margin-top: 6rem;
  }
}

.project_card {
  background: $white;
  box-shadow: 0.4rem 0.4rem 2.4rem rgba(0, 0, 0, 0.12);
  border-radius: 1.6rem;
  display: flex;
  position: relative;
  cursor: pointer;
}

.project_img {
  position: absolute;
  left: 2rem;
  bottom: 2rem;

  & img {
    border-radius: 2rem;
  }
}

.title {
  font-style: normal;
  font-weight: 700;
  font-size: 1.6rem;
  color: $text;
  padding-bottom: 3rem;
}

.price {
  font-style: normal;
  font-weight: 700;
  font-size: 2.4rem;
  color: $blue;
  padding-top: 0.5rem;
}

.investing {
  font-weight: 400;
  font-size: 1.4rem;
  color: $grey;
}

.content_wrapper {
  padding: 2rem;
  margin-left: auto;
  width: 50%;
}

.link {
  position: absolute;
  right: 1.6rem;
  bottom: 1.6rem;
  text-indent: -9999px;
  display: block;
  width: 2.4rem;
  height: 2.4rem;
  background: url("/img/arrow-right.svg") center no-repeat;
  margin-left: auto;
  transition: all 0.3s ease-in-out;
}
.img_inside {
  flex-direction: row !important;
  padding: 0.8rem;

  .content_wrapper {
    padding: 0;
    padding-left: 1.6rem;
    margin-left: auto;
    width: 100%;
    align-self: center;
  }

  .project_img {
    position: relative;
    left: 0;
    bottom: 0;

    img {
      width: 10rem;
      height: 10rem;
      object-fit: cover;
    }
  }

  .title {
    padding-bottom: 1.1rem;
  }
}

@media screen and (max-width: ($bp-xs)) {
  .content_wrapper {
    padding: 0;
    padding-left: 1.6rem;
    margin-left: auto;
    width: 100%;
    align-self: center;
  }

  .project_img {
    position: relative;
    left: 0;
    bottom: 0;

    img {
      width: 10rem;
      height: 10rem;
      object-fit: cover;
    }
  }

  .title {
    padding-bottom: 1.6rem;
  }
}

@media screen and (min-width: ($bp-mobile+1px)) {
  .title {
    font-size: 2rem;
    line-height: 1.7rem;
  }

  .project_card {
    &:hover {
      .link {
        right: 1.1rem;
      }
    }
  }
}
